const appConfig = {
    apiPrefix: '/api',
    apiReportsPrefix: '/api',
    apiPrefixServer: 'https://api.syslogistica.com/api',
    //apiPrefixServer: 'http://localhost:3050/api',  
    apiReportsServer: 'https://reports.syslogistica.com/api', //'https://reports.syslogistica.com/api',//'https://localhost:44351/api' , //'https://reports.syslogistica.com/api', //http://localhost:49510/api, 
    authenticatedEntryPath: '/pages/welcome' ,
    AZURE_STORAGE_CONNECTION_STRING: "DefaultEndpointsProtocol=https;AccountName=ucontrolblob;AccountKey=+rQxrygR38wVj7Xq+f6hNKw93h9QMIYt1auEWYMhEphnvt4DqwEIiJAO8PkntJn0N1onkrmOvkhxCT5tr02Yxw==;EndpointSuffix=core.windows.net",
    AZURE_containername:"sslogisticareports",
    storageAccountName: "sslogistica",
    sasToken: "sp=racwdli&st=2024-08-08T19:40:10Z&se=2028-08-09T03:40:10Z&sv=2022-11-02&sr=c&sig=HBkw9%2BjLuZc2RwaOmytz1zWy49keOOG0VwmsOrtjzXw%3D",
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: true
}

export default appConfig